<template>
  <div class="main">
    <div style="min-height: 700px; padding-top: 20px; background: #fff">
      <div class="itemName">
        <el-divider>
          <div class="dividerFont">
            <span>我的订单</span>
            <div
              style="width: 60px; height: 2px; background-color: #029aff"
            ></div>
          </div>
        </el-divider>
      </div>

      <div class="content">
        <div class="tabs">
          <div
            class="tabItem"
            v-for="(item, index) in tabs"
            :key="index"
            @click="chooseTab(item, index)"
            :class="tabActive == index ? 'tabActive' : ''"
          >
            {{ item }}
          </div>
        </div>

        <div class="orderBox" v-if="orderList.length > 0">
          <div
            class="orderItem"
            v-for="(item, index) in orderList"
            :key="index"
          >
            <div class="orderTop">
              <span>订单号:{{ item.orderNo }}</span>
              <span :class="item.orderStatus == 3 ? 'gray' : ''">{{
                getOrderStatus(item.orderStatus)
              }}</span>
              <!-- <span v-if="item.payStatus == '1'">待支付</span>
              <span v-else-if="item.payStatus == '2'">已支付</span> -->
            </div>
            <div class="orderCenter">
              <div class="orderType">{{ getOrderType(item.orderType) }}</div>
              <div class="orderTitle">{{ item.productName }}</div>
            </div>

            <div class="orderBottom">
              <div class="payBox">
                <span>实付金额：</span>
                <span
                  class="payNum"
                  :class="item.orderStatus == 3 ? 'gray' : ''"
                  >{{ "￥" + item.amount }}</span
                >
              </div>

              <div class="orderPay">
                <el-button
                  size="mini"
                  round
                  type="primary"
                  v-if="item.orderStatus == 1"
                  @click="goPay(item)"
                  >立即支付</el-button
                >
                <el-button
                  size="mini"
                  round
                  type="warning"
                  v-if="item.orderStatus == 1"
                  @click="getJobOrder(item)"
                  >取消</el-button
                >
                <!-- <el-button
                  size="mini"
                  round
                  type="warning"
                  v-if="item.orderStatus == 3"
                  >已取消</el-button
                > -->
              </div>
            </div>
          </div>
        </div>
      </div>
       <el-empty v-if="total == 0" description="空空如也"></el-empty>
    </div>
    <pay
      :dialogVisible="dialogVisible"
      :payInfo="payInfo"
      :payStatus="payStatus"
    ></pay>
    <div class="pagination">
      <el-pagination
        :background="true"
        layout="prev, pager, next"
        :total="total"
        :page-size="10"
        @current-change="chengePagination"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import { jobOrderPage, jobOrderIte } from "@/api/personalCenter/personalCenter";
import pay from "@/components/pay/pay";
import moment from "moment";

export default {
  name: "",
  data() {
    return {
      dict: [],
      orderStatus: [],
      orderType: [],
      tabs: ["全部订单", "待支付", "已支付","已失效"],
      tabActive: 0,

      minutes: 15,
      seconds: 0,

      params: {
        pageNo: 1,
        pageSize: 10,
        jobUserId: "",
        orderStatus: "",
        userType: 1,
      },

      orderList: [],
      tabIndex: 0,
      total: 1,
      dialogVisible: false,
      payStatus: "",
      payInfo: {},
    };
  },
  watch: {},
  props: {},
  components: {
    pay,
  },
  computed: {},
  created() {
    this.dict = JSON.parse(localStorage.getItem("dict"));
    this.orderStatus = this.dict.filter(
      (i) => i.code == "order_status"
    )[0].children;
    this.orderType = this.dict.filter(
      (i) => i.code == "order_type"
    )[0].children;
    this.params.jobUserId = localStorage.getItem("userId");
    this.getJobOrderPage();
  },
  mounted() {},
  methods: {
    chooseTab(item, index) {
      this.dialogVisible = false;
      this.tabActive = index;
      if (item == "全部订单" && this.tabIndex != index) {
        this.params.orderStatus = "";
        this.params.pageNo = 1;
        this.getJobOrderPage();
      }
      if (item == "待支付" && this.tabIndex != index) {
        this.params.orderStatus = 1;
        this.params.pageNo = 1;
        this.getJobOrderPage();
      }
      if (item == "已支付" && this.tabIndex != index) {
        this.params.orderStatus = 2;
        this.params.pageNo = 1;
        this.getJobOrderPage();
      }
      if (item == "已失效" && this.tabIndex != index) {
        this.params.orderStatus = 3;
        this.params.pageNo = 1;
        this.getJobOrderPage();
      }
      // 记录下上次点击tab避免多次点击多次查询
      this.tabIndex = index;
    },
    getJobOrderPage() {
      jobOrderPage(this.params).then((res) => {
        console.log(res, "订单");
        this.orderList = res.data.rows;
        this.total = res.data.totalRows;
      });
    },

    getJobOrder(item) {
      // if (
      //   item.createTime >
      //   moment(new Date()).format("YYYY") - moment().format("YYYY")
      // )
      item.orderStatus = "3";
      jobOrderIte(item).then((res) => {
        this.getJobOrderPage();
      });
    },

    chengePagination(cur) {
      this.params.pageNo = cur;
      this.getJobOrderPage();
    },
    getOrderStatus(code) {
      if (code) {
        return this.orderStatus.filter((i) => i.code == code)[0].name;
      } else {
        return "";
      }
    },
    getOrderType(code) {
      if (code) {
        return this.orderType.filter((i) => i.code == code)[0].name;
      } else {
        return "";
      }
    },
    goPay(item) {
      this.payStatus = item.orderNo;
      let userId = localStorage.getItem("userId");
      if (item.orderType != "3") {
        this.payInfo = {
          amount: item.amount,
          jobUserId: userId,
          num: item.num,
          payMethod: item.payMethod, //  1 wx ,2 ali
          orderType: item.orderType, // 1 会员 ,2 权益 3 课程
          userType: "1", // 1 个人 ,2 企业
          productName: item.productName,
          productId: item.productId,
          id: item.id,
        };
      } else {
        this.payInfo = {
          amount: item.amount,
          jobUserId: userId,
          num: item.num,
          payMethod: item.payMethod, //  1 wx ,2 ali
          orderType: item.orderType, // 1 会员 ,2 权益 3 课程
          userType: "1", // 1 个人 ,2 企业
          productName: item.productName,
          productId: item.productId,
          id: item.id,
        };
      }
    },
  },
  filters: {},
};
</script>

<style scoped lang="scss">
.gray {
  color: #999;
}

.pagination {
  text-align: center;
  margin-top: 20px;
}

::v-deep {
  .el-pagination.is-background .btn-prev {
    /*对下一页的按钮样式进行修改*/
    background: #fff;
  }
  .el-pagination.is-background .btn-next {
    /*对上一页的按钮样式进行修改*/
    background: #fff;
  }
  .el-pagination.is-background .el-pager li:not(.disabled) {
    background-color: #fff; // 进行修改未选中背景和字体
    // color: #fff;
  }
  .el-pagination.is-background .el-pager li:not(.disabled).active {
    background-color: rgb(19, 138, 206); // 进行修改选中项背景和字体
    color: rgb(255, 255, 255);
  }
}

.itemName {
  padding: 0 86px;
  padding-bottom: 22px;
  .dividerFont {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0 50px;
    span {
      font-size: 24px;
      margin-bottom: 6px;
    }
  }
}

.content {
  padding: 0 45px;
}
.tabs {
  display: flex;
  margin-bottom: 30px;
  .tabItem {
    height: 40px;
    line-height: 40px;
    font-size: 18px;
    color: #999;
    padding: 0 20px;
    margin-right: 10px;
    cursor: pointer;
  }
  .tabItem:hover {
    text-decoration: underline;
  }
}

.tabActive {
  background-color: #36aeff;
  color: #fff !important;
  border-radius: 4px;
}

.orderItem {
  margin-bottom: 22px;
  box-shadow: 0px 0px 2px 0px #ccc;
  border-radius: 6px;
  padding: 0 14px;
  .orderTop {
    height: 42px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 14px;
    border-bottom: 1px solid #eee;
    span:nth-child(2) {
      color: #26a5ff;
    }
  }
  .orderCenter {
    height: 100px;
    display: flex;
    .orderType {
      background-color: #d1ecffff;
      color: #0597ff;
      font-size: 12px;
      height: 22px;
      line-height: 22px;
      padding: 0 8px;
      border-radius: 4px;
      margin-top: 20px;
    }
    .orderTitle {
      font-size: 16px;
      margin-left: 25px;
      margin-top: 20px;
      font-weight: bold;
    }
  }
  .orderBottom {
    height: 52px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-top: 1px solid #eee;
    .payBox {
      display: flex;
      align-items: center;
      span:nth-child(1) {
        color: #666;
        font-size: 12px;
      }
      .payNum {
        font-size: 18px;
        color: #f7484b;
      }
    }
    .orderPay {
      display: flex;
      justify-content: flex-end;
    }
  }
}
</style>
